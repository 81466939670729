import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Layout.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Layout = ({ children }) => (
  <div className={styles.root}>
    <main className={styles.main}>{children}</main>
  </div>
);

Layout.propTypes = propTypes;

export default Layout;
