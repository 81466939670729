import React from 'react';

import { Layout, Seo } from 'App/components';
import { Home } from 'App/modules/home/components';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

const IndexPage = () => (
  <Layout>
    <Seo
      title="Main"
      // keywords={['gatsby', 'application', 'react']}
    />
    <Home />
  </Layout>
);

export default IndexPage;
