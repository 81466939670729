import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Ajzk from 'App/modules/home/components/Ajzk/Ajzk';

import * as styles from './Home.module.scss';

const Home = () => (
  <div className={styles.root}>
    <Ajzk />
    <div className={styles.hi}>
      <span className={styles.text}>Say hi:</span>
      <a
        href="https://ajzk.pl/linkedin"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon
          className={styles.linkedin}
          icon={['fab', 'linkedin']}
        />
      </a>
    </div>
  </div>
);

export default Home;
