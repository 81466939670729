import React, { createRef, Component } from 'react';
import classnamesBind from 'classnames/bind';

import * as styles from './Ajzk.module.scss';

const classnames = classnamesBind.bind(styles);

class Ajzk extends Component {

  constructor() {
    super();

    this.state = {
      span1Width: null,
      span2Width: null,
      isCollapsed: false,
      windowWidth: null,
    };

    this.span1Ref = createRef();
    this.span2Ref = createRef();
  }

  componentDidMount() {
    this.setWidths();
    window.addEventListener('resize', this.setWidths);
  }

  setWidths = () => {
    const { isCollapsed } = this.state;
    this.setState({
      windowWidth: window.innerWidth,
    }, () => {
      this.setState({
        span1Width: this.span1Ref.current.clientWidth,
        span2Width: this.span2Ref.current.clientWidth,
      }, () => setTimeout(() => {
        if (!isCollapsed) {
          this.setState({
            isCollapsed: true,
          });
        }
      }, 500));
    });
  };

  render() {
    const {
      isCollapsed,
      span1Width,
      span2Width,
      windowWidth,
    } = this.state;

    const isExpandable = windowWidth > 290;

    return (
      <div className={classnames('root', { isCollapsed })}>
        <span
          className={classnames('span', 'hidden')}
          ref={this.span1Ref}
        >
          ndrze
        </span>
        <span
          className={classnames('span', 'hidden')}
          ref={this.span2Ref}
        >
          iole
        </span>
        {windowWidth === null ? (
          <div />
        ) : (
          <div className={classnames('name', { isCollapsed, isExpandable })}>
            <span className={styles.span}>a</span>
            <span
              style={{ maxWidth: span1Width }}
              className={classnames('span', 'isCollapsable')}
            >
              ndrze
            </span>
            <span className={styles.span}>j</span>
            <span className={styles.span}>z</span>
            <span
              style={{ maxWidth: span2Width }}
              className={classnames('span', 'isCollapsable')}
            >
              iole
            </span>
            <span className={styles.span}>k.pl</span>
          </div>
        )}
      </div>
    );
  }

}

export default Ajzk;
